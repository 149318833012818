import * as React from "react"
import Layout from "../components/layout"
import { BaseImage, DuoImage } from "../components/photos"
import * as styles from "./index.module.scss"


import pic1 from "../images/wj/p1.png"
import d1 from "../images/wj/d1.png"
import d2 from "../images/wj/d2.png"
import pic3 from "../images/wj/p2.png"
import d3 from "../images/wj/d3.png"
import d4 from "../images/wj/d4.png"
import pic5 from "../images/wj/p3.png"
import pic6 from "../images/wj/p4.png"
import pic7 from "../images/wj/p5.png"
import pic8 from "../images/wj/p6.png"


class WillJenna extends React.Component {
    render() {
        return (
            <Layout title="Portraits - Will/Jenna">
                <div className={styles.photoBlog}>
                    <p>
                        <a href="/">
                            Home
                        </a>
                        <h3>Will and Jenna</h3>
                        <div>Mt. Tam - December 2020</div>
                    </p>
                    <BaseImage imgSrc={pic1} />
                    <DuoImage imgSrc={d1} imgSrc2={d2} />
                    <BaseImage imgSrc={pic3} />
                    <DuoImage imgSrc={d3} imgSrc2={d4} />
                    <BaseImage imgSrc={pic5} />
                    <BaseImage imgSrc={pic6} />
                    <BaseImage imgSrc={pic7} />
                    <BaseImage imgSrc={pic8} />
                </div>
            </Layout>
        )
    }
}

export default WillJenna
